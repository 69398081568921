.zoned-comfort-solutions__cta-container {
  text-align: center;
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 30px; 
}

.zoned-comfort-solutions__cta-container-title-1,
.zoned-comfort-solutions__cta-container-title-2 {
  display: block;
  color: #525252;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;
}
.zoned-comfort-solutions__cta-container-title-1 {
  font-size: 28px;
}
.zoned-comfort-solutions__cta-container-title-2 {
  font-size: 16px;
  margin-top: 8px;
}
.zoned-comfort-solutions__cta-container-title-divider {
  max-width: 180px;
  margin: 15px auto;
  height: 0;
  border-top: 1px solid #969696;
}
.zoned-comfort-solutions__cta-container-copy {
  font-size: 14px;
  color: #525252;
  line-height: 25px;
  margin-bottom: 25px;
  font-weight: 400;
  opacity: 0.8;
}

@media screen and (max-width: 820px) {
  .zoned-comfort-solutions__cta-container {
    background: #fff;
    max-width: 90vw;
    margin: 0 auto;
  }

  .zoned-comfort-solutions__cta-container-copy {
    font-size: 16px;
    max-width: 98%;
    margin: 0 auto 20px;
  }
}