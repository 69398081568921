.products-page {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.products-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto;
    padding: 12px;
    width: 30%;
    list-style: none;
    background-color: white;
    border-radius: 7px;
}

.products-breadcrumbs {
    flex: 0 0 93%;
    margin-bottom: 20px;
}

.products-pagination-link {
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    padding: 7px 12px;
}

.products-pagination-link-next a {
    color: rgb(60, 107, 160);
    cursor: pointer;
}

.products-pagination-link-previous a {
    color: rgb(60, 107, 160);
    cursor: pointer;
}

.products-pagination-link-active {
    background-color: rgb(60, 107, 160)
}

.products-pagination-a-link {
    color: rgb(48, 91, 155);
    cursor: pointer;
}

.products-pagination-a-link:hover {
    color: rgb(48, 91, 155);
}

.products-pagination-a-link-active {
    color: white !important;
}

.filter-mobile-expansion {
    display: none;
}

@media screen and (max-width: 1150px) {
    .filter-mobile-expansion {
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    .filter-drawer {
        display: none;
    }

    .products-filter-wrapper {
        width: 100%;
    }

    .products-pagination {
        width: 90%;
        margin: 0 auto;
    }

    .products-outer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 0;
    }

    .products-page {
        display: block;
    }

    .products-filter-title {
        display: none;
    }

    .products-breadcrumbs {
        width: 90%;
        margin: 15px auto;
    }
}