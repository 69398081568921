.image-and-info-details {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin: 20px auto;
}

@media screen and (max-width: 820px) {
    .image-and-info-details {
        display: block;
    }
}