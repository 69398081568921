.banner {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    width: 50%;
    box-shadow: 0 1px 20px rgba(199, 199, 199, 0.671);
}


@media screen and (max-width: 820px) {
    .about-page-banner {
        width: 100%;
    }

    .banner {
        width: 100%;
    }
}