.product-details-img {
    padding: 80px 0;
    width: 85%;
    margin: 30px 30px 0 30px;
    display: block;
}

.product-details-img-small {
    display: block;
    width: 70px;
    height: auto;
    padding: 15px 5px;
    margin: 0px 0px 10px 10px;
    border: 2px solid rgb(15, 163, 231);
    cursor: pointer;
}

.product-details-image-wrapper {
    border: 1px solid rgb(190, 190, 190);
    margin:20px;
    padding:0;
}