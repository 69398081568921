.benefits-list {
  background-color: #f9f9f9;
}
.benefits-list span {
  background-color: #f9f9f9;
  display: block;
  padding: 86px 0;
  -webkit-transition: 0.25s background-color ease;
  transition: 0.25s background-color ease;
}
.benefits-list .benefit-wrapper {
  margin: 0 auto;
  max-width: 760px;
  padding: 0 66px 0 160px;
  position: relative;
  width: 90%;
}
.benefits-list .benefit-wrapper:before {
  background: url("../../assets/img/icon-benefits.png") no-repeat left top;
  background-size: 120px 120px;
  display: block;
  height: 120px;
  overflow: hidden;
  position: relative;
  text-indent: 100%;
  width: 120px;
  white-space: nowrap;
  content: '';
  left: 0;
  margin-top: -60px;
  position: absolute;
  top: 50%;
  background-size: inherit;
}

.benefits-list .benefit-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 90px;
}
.benefits-list h3 {
  background: url('../../assets/img/feature-sep.png') no-repeat left bottom;
  color: #5f5f5f;
  font-size: 30px;
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
.benefits-list p {
  color: #848484;
  font-size: 16px;
  margin: 0;
}
.benefits-list span:hover .benefit-wrapper:before {
  background-position: right top;
}
.benefits-list span:hover .benefit-wrapper:after {
  opacity: 1;
}
.benefits-list .benefit:nth-child(1) span {
  padding-top: 100px;
}
.benefits-list .benefit:nth-child(1) span:hover {
  background-color: #97c5e5;
}
.benefits-list .benefit:nth-child(1) span:hover h3 {
  color: #456f8c;
}
.benefits-list .benefit:nth-child(1) span:hover p {
  color: #3c5f78;
}
.benefits-list .benefit:nth-child(2) span .benefit-wrapper:before {
  background-position: left -228px;
}
.benefits-list .benefit:nth-child(2) span:hover {
  background-color: #3d7f3a;
}
.benefits-list .benefit:nth-child(2) span:hover .benefit-wrapper:before {
  background-position: right -228px;
}
.benefits-list .benefit:nth-child(2) span:hover h3 {
  color: #173516;
}
.benefits-list .benefit:nth-child(2) span:hover p {
  color: #265224;
}
.benefits-list .benefit:nth-child(3) span .benefit-wrapper:before {
  background-position: left -456px;
}
.benefits-list .benefit:nth-child(3) span:hover {
  background-color: #ff671b;
}
.benefits-list .benefit:nth-child(3) span:hover .benefit-wrapper:before {
  background-position: right -456px;
}
.benefits-list .benefit:nth-child(3) span:hover h3 {
  color: #a33906;
}
.benefits-list .benefit:nth-child(3) span:hover p {
  color: #aa3f0a;
}
.benefits-list .benefit:nth-child(4) span .benefit-wrapper:before {
  background-position: left -684px;
}
.benefits-list .benefit:nth-child(4) span:hover {
  background-color: #b096da;
}
.benefits-list .benefit:nth-child(4) span:hover .benefit-wrapper:before {
  background-position: right -684px;
}
.benefits-list .benefit:nth-child(4) span:hover h3 {
  color: #665482;
}
.benefits-list .benefit:nth-child(4) span:hover p {
  color: #5c4b76;
}
.benefits-list .benefit:nth-child(5) span .benefit-wrapper:before {
  background-position: left -912px;
}
.benefits-list .benefit:nth-child(5) span:hover {
  background-color: #63d0df;
}
.benefits-list .benefit:nth-child(5) span:hover .benefit-wrapper:before {
  background-position: right -912px;
}
.benefits-list .benefit:nth-child(5) span:hover h3 {
  color: #1a6777;
}
.benefits-list .benefit:nth-child(5) span:hover p {
  color: #29727f;
}
.benefits-list .benefit:nth-child(6) span {
  padding-bottom: 100px;
}
.benefits-list .benefit:nth-child(6) span .benefit-wrapper:before {
  background-position: left -1140px;
}
.benefits-list .benefit:nth-child(6) span:hover {
  background-color: #f7d93e;
}
.benefits-list .benefit:nth-child(6) span:hover .benefit-wrapper:before {
  background-position: right -1140px;
}
.benefits-list .benefit:nth-child(6) span:hover h3 {
  color: #947e0f;
}
.benefits-list .benefit:nth-child(6) span:hover p {
  color: #84700b;
}
@media screen and (max-width: 640px) {
  .benefits-list span {
    padding: 0;
  }
  .benefits-list .benefit-wrapper {
    border-bottom: 1px solid #e1e6eb;
    padding: 52px 0;
    text-align: center;
    width: 80%;
  }
  .benefits-list .benefit-wrapper:before {
    background-size: 205px auto;
    height: 89px;
    margin: 0 auto 2em;
    position: static;
    width: 89px;
  }
  .benefits-list .benefit-wrapper:after {
    display: none;
  }
  .benefits-list h3 {
    background-position: center bottom;
    background-size: 90px 2px;
    font-size: 24px;
    margin-bottom: 1em;
    padding-bottom: 0.75em;
  }
  .benefits-list p {
    font-size: 14px;
    padding: 0 10%;
  }
  .benefits-list .benefit:nth-child(1) span {
    padding-top: 0;
  }
  .benefits-list .benefit:nth-child(2) span .benefit-wrapper:before {
    background-position: left -166px;
  }
  .benefits-list .benefit:nth-child(2) span:hover .benefit-wrapper:before {
    background-position: right -166px;
  }
  .benefits-list .benefit:nth-child(3) span .benefit-wrapper:before {
    background-position: left -334px;
  }
  .benefits-list .benefit:nth-child(3) span:hover .benefit-wrapper:before {
    background-position: right -334px;
  }
  .benefits-list .benefit:nth-child(4) span .benefit-wrapper:before {
    background-position: left -501px;
  }
  .benefits-list .benefit:nth-child(4) span:hover .benefit-wrapper:before {
    background-position: right -501px;
  }
  .benefits-list .benefit:nth-child(5) span .benefit-wrapper:before {
    background-position: left -666px;
  }
  .benefits-list .benefit:nth-child(5) span:hover .benefit-wrapper:before {
    background-position: right -666px;
  }
  .benefits-list .benefit:nth-child(6) span {
    padding-bottom: 0;
  }
  .benefits-list .benefit:nth-child(6) span .benefit-wrapper:before {
    background-position: left -834px;
  }
  .benefits-list .benefit:nth-child(6) span:hover .benefit-wrapper:before {
    background-position: right -834px;
  }
}