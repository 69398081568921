.color-palette {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.circle {
  border-radius: 18px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 15px;
  border: 1px solid rgba(151, 151, 151, 0.582);
}

.circle-outline {
    border: 3px solid rgb(120, 151, 255);
    box-shadow: 0 0 2px #888;
}