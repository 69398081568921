.contact-page-container {
    width: 90vw;
    background-color: rgb(255, 255, 255);
    margin: 120px auto;
    padding: 20px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.contact-page-info {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(192, 192, 192);
}

@media screen and (max-width: 820px) { 
    .contact-page-info {
        border-left: none;
        border-top: 1px solid rgb(226, 226, 226);
    }

    .contact-page-container { 
        display: block;
    }

    .contact-page-info img {
        width: 65%;
    }
}
