.form-container {
    margin: 0 40px;
    padding: 10px 40px;
}

.form-container-title {
    margin-bottom: 0;
    padding-bottom: 0;
}

.form-post-fail {
    color: rgb(242, 31, 60);
    margin-left: 20px;
    font-size: 12px;
}

.form-post-success {
    color: rgb(68, 217, 63);
    margin-left: 20px;
    font-size: 12px;
}

@media screen and (max-width: 820px) { 
    .form-container {
        padding: 10px;
        margin: 0 20px;
    }
}