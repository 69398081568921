.buying-zoned-comfort {
  background: #aaaaaa;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
}

.buying-zoned-comfort__content {
  text-align: left;
  max-width: 500px;
  padding: 150px 0 150px 120px;
}


.buying-zoned-comfort__title br {
  display: none;
}

.buying-zoned-comfort__divider {
  max-width: 200px;
  height: 0;
  border: 1px solid #8f8f8f;
  text-align: left;
  margin: 20px 0 25px;
}


.buying-zoned-comfort__image-container {
  background: url('../../assets/img/room.jpg') center center no-repeat;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 56%;
  box-shadow: -30px 0px 30px -15px rgb(150, 150, 150);
}

.buying-zoned-comfort__image-container-webp {
  background: url('../../assets/img/webp/room.webp') center center no-repeat;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 56%;
  box-shadow: -30px 0px 30px -15px rgb(150, 150, 150);
}

@media screen and (max-width: 1440px) {
  .buying-zoned-comfort__image-container {
    width: 54%;
  }

  .buying-zoned-comfort__image-container-webp {
    width: 54%;
  }
}

@media screen and (max-width: 1113px) {
  .buying-zoned-comfort__content {
    padding-left: 40px;
  }
}

@media screen and (max-width: 820px) {
  .buying-zoned-comfort {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: 100%;
    margin-top: 60px;
  }

   .buying-zoned-comfort__content {
    padding: 60px 0;
    max-width: 90%;
    margin: 0 auto;
  }

  .buying-zoned-comfort__title br {
    display: block;
  }

   .buying-zoned-comfort__image-container {
    width: 100%;
    min-height: 245px;
    position: relative;
    box-shadow: none;
  }

  .buying-zoned-comfort__image-container-webp {
    width: 100%;
    min-height: 245px;
    position: relative;
    box-shadow: none;
  }
}
