.products-filter-title {
    background-color: rgb(50, 125, 187);
    padding: 10px;
    -webkit-box-shadow: 0 6px 4px -4px rgb(185, 185, 185);
    -moz-box-shadow: 0 6px 4px -4px rgb(185, 185, 185);
    box-shadow: 0 6px 4px -4px rgb(185, 185, 185);
}

.products-filter-title h5 {
    display: inline-block;
    color: white;
    margin: 0 0 0 15px;
    font-weight: 400;
}