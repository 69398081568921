.similar-products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px;
    background-color: rgb(240, 239, 239);
    border-radius: 7px;
    margin-bottom: 30px;
}