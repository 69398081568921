.about-page-navpills {
    padding: 30px;
    margin: 0 auto;
    width: 80%;
}

.about-page-banner {
    background-color: rgba(252, 252, 252, 0.884);
    border-radius: 7px;
    box-shadow: 0 1px 20px rgba(199, 199, 199, 0.671);
    padding: 20px 20px 40px 20px;
    width: 50%;
}

@media screen and (max-width: 820px) {
    .about-page-navpills {
        width: 100%;
    }

    .about-page-banner {
        width: 100%;
    }
}