td {
    padding: 10px;
    border: 0.25px solid rgb(255, 255, 255);
}

th {
    padding: 10px;
}

table {
    width: 100%;
    border-spacing: 0 !important;
}