.benefits-tagline {
  background: #f2f0f4;
  color: #909096;
  margin: 0 auto;
  max-width: 1800px;
  padding: 50px 0;
  text-align: center;
  width: 100%;
  border-radius: 7px;
}

.banner {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    width: 50%;
}

.banner-title {
    color: rgb(120,120,120);
    font-weight: 400
}

.banner-subtitle {
    color: rgb(100,100,100);
}

@media screen and (max-width: 640px) {
    .banner {
        width: 100%
    }

    .banner-title {
        font-size: 40px
    }

    .banner-subtitle {
        font-size: 15px;
    }

    .info-subtitle {
        padding-right: 10px;
        padding-left: 10px;
    }
}