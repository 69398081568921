.products-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    height: 1100px;
    overflow: scroll;
    padding-bottom: 50px;
}

.products-outer-wrapper {
    width: 70%;
}