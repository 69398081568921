.content {
    margin: 5px;
}

.zoned-comfort-solutions {
  position: relative;
  top: 5px;
  width: 100%;
  min-height: 500px;
  background-color: rgb(245, 245, 245);
  padding: 30px 0 0;
  border-radius: 7px;
}

.zoned-comfort-solutions__intro {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.zoned-comfort-solutions__title-divider {
  height: 0;
  border-top: 1px solid #83838a;
  max-width: 200px;
  margin: 20px auto 25px;
}


.zoned-comfort-solutions__content {
  position: relative;
  max-width: 820px;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  padding: 0 0 20px 0;
}

.zoned-comfort-solutions__nav {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.zoned-comfort-solutions__nav-item {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 26px 0;
  background: #f9f9f9;
  font-size: 12px;
  width: 50%;
  position: relative;
  font-weight: 400;
}
.zoned-comfort-solutions__nav-item:after {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #90adad;
  width: 0px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
}

.zoned-comfort-solutions__nav-item .zoned-comfort-solutions__nav-item-button {
  color: #898989;
  line-height: 1;
}
.zoned-comfort-solutions__nav-item:hover,
.zoned-comfort-solutions__nav-item.active {
  border-top: 1px solid #90adad;
  border-bottom: 1px solid #90adad;
  background: #90adad;
}
.zoned-comfort-solutions__nav-item:hover:after,
.zoned-comfort-solutions__nav-item.active:after {
  display: block;
}
.zoned-comfort-solutions__nav-item:hover .zoned-comfort-solutions__nav-item-button,
.zoned-comfort-solutions__nav-item.active .zoned-comfort-solutions__nav-item-button {
  color: #f6f6f6;
}
.zoned-comfort-solutions__cta-slider {
  position: relative;
}
.zoned-comfort-solutions__cta-slider.move-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.zoned-comfort-solutions__cta-slider-window {
  overflow: hidden;
}



.zoned-comfort-solutions__cta-divider {
  width: 1px;
  background: #cbcbcb;
  height: 300px;
  margin: 30px 100px 0;
  display: inline-block;
  vertical-align: middle;
}

.zoned-comfort-solutions__cta-img {
  width: 125px;
  margin: 0 auto 25px;
}

.zoned-comfort-solutions__intro,
.zoned-comfort-solutions__content {
  position: relative;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.zoned-comfort-solutions--active {
  min-height: 0;
}
.zoned-comfort-solutions--active .zoned-comfort-solutions__intro,
.zoned-comfort-solutions--active .zoned-comfort-solutions__content {
  height: 0;
  left: 50%;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
}
.zoned-comfort-solutions .video-container-wrap {
  background: #f0f0f0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0 60px 80px;
}

.zoned-comfort-solutions .video-container-wrap .btn--close {
  right: 90px;
  top: 30px;
}

/* 820 px */

@media screen and (max-width: 820px) {
  .zoned-comfort-solutions {
    padding: 60px 0 0;
    width: 100%;
  }

  .zoned-comfort-solutions__intro {
    padding-bottom: 60px;
  }

  .zoned-comfort-solutions__title {
    max-width: 300px;
    line-height: 62px;
    margin: 0 auto;
  }

   .zoned-comfort-solutions__description {
    max-width: 290px;
  }

  .zoned-comfort-solutions__content {
    background: #fff;
    padding: 0 0 10px;
  }

  .zoned-comfort-solutions__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .zoned-comfort-solutions__cta-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 55px;
    width: 200%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .zoned-comfort-solutions__cta-slider.move-left {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .zoned-comfort-solutions__cta-slider-window {
    margin-top: 55px;
    max-width: 91vw;
    margin: 0 auto;
  }

  .zoned-comfort-solutions__cta-divider {
    display: none;
  }

  .zoned-comfort-solutions .video-container-wrap {
    padding: 0 30px 30px;
    margin-top: -30px;
  }

  .zoned-comfort-solutions .video-container-wrap .btn--close {
    right: 60px;
  }
}


