footer {
    background-color: #0f121a;
    margin-top: 110px;
    padding-top: 30px;
}

.footer-copyright {
    text-align: center;
    padding: 10px;
    background-color: rgb(12, 15, 20);
    color: white;
}

.container-fluid {
    padding-top: 15px;
    padding-bottom: 20px;
}

.text-white {
    color: white;
    margin-top: 0;
    padding-top: 0;
}

#cel_number  {
    color: rgb(202, 216, 255);
}

#email {
    color: rgb(202, 216, 255);
}

.footer-link {
    color: rgb(75, 123, 255);
}
.footer-list {
    margin-top: 0;
    padding-left: 20px;
}

.footer-link:hover {
    color: rgb(65, 116, 255);
}

.footer-list li {
    list-style: none;
    padding: 5px 0;
}

.footer-list li a {
    color: rgb(241, 241, 241);
}

.footer-list li a:hover {
    color: rgb(219, 219, 219);
}

.footer-row {
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
}

@media screen and (max-width: 820px) {
    .footer-list {
        margin-top: 30px;
    }
}