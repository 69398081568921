.products-filter-body {
    padding: 0 20px;
}

.products-filter-body p {
    font-size: 17px;
    font-weight: 700;
    padding: 0;
    margin: 15px 0 0 0px;
}
