@import url(https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One);
/*!

 =========================================================
 * Material Kit React v1.8.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-size: 1rem; }

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit; }

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em; }

h2 {
  font-size: 2.25rem;
  line-height: 1.5em; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em; }

h4 {
  font-size: 1.125rem;
  line-height: 1.5em; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em; }

h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500; }

p {
  font-size: 14px;
  margin: 0 0 10px; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  background-color: #e5e5e5;
  color: #3c4858;
  margin: 0;
  font-size: 1rem;
  text-align: left; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:focus {
    outline: 0; }

a {
  color: #9c27b0;
  text-decoration: none;
  background-color: transparent; }
  a:hover, a:focus {
    color: #89229b;
    text-decoration: none; }

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400; }

small {
  font-size: 75%;
  color: #777;
  font-weight: 400; }

img {
  vertical-align: middle;
  border-style: none; }

form {
  margin-bottom: 1.125rem; }

/*! nouislider - 14.0.2 - 6/28/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
/*

This file was modified by Creative-Tim

*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  -webkit-transform-style: flat;
          transform-style: flat; }

.noUi-connect {
  height: 100%;
  width: 100%; }

.noUi-origin {
  height: 10%;
  width: 10%; }

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0; }

.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  border: 1px solid #9c27b0;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  left: 0px;
  top: -7px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -4px;
  left: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background-color: #c8c8c8;
  border-radius: 3px; }

.noUi-connects {
  border-radius: 3px; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-target .noUi-handle {
  border: 1px solid #333; }

.noUi-target.slider-primary .noUi-connect, .noUi-target.slider-primary.noUi-connect {
  background-color: #9c27b0; }

.noUi-target.slider-primary .noUi-handle {
  border-color: #9c27b0; }

.noUi-target.slider-info .noUi-connect, .noUi-target.slider-info.noUi-connect {
  background-color: #00bcd4; }

.noUi-target.slider-info .noUi-handle {
  border-color: #00bcd4; }

.noUi-target.slider-success .noUi-connect, .noUi-target.slider-success.noUi-connect {
  background-color: #4caf50; }

.noUi-target.slider-success .noUi-handle {
  border-color: #4caf50; }

.noUi-target.slider-warning .noUi-connect, .noUi-target.slider-warning.noUi-connect {
  background-color: #ff9800; }

.noUi-target.slider-warning .noUi-handle {
  border-color: #ff9800; }

.noUi-target.slider-danger .noUi-connect, .noUi-target.slider-danger.noUi-connect {
  background-color: #f44336; }

.noUi-target.slider-danger .noUi-handle {
  border-color: #f44336; }

.noUi-target.slider-rose .noUi-connect, .noUi-target.slider-rose.noUi-connect {
  background-color: #e91e63; }

.noUi-target.slider-rose .noUi-handle {
  border-color: #e91e63; }

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9c27b0), to(#9c27b0)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    display: block;
    width: 100%;
    color: #555; }
  .rdt input.form-control:focus {
    outline: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9c27b0), to(#9c27b0)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent; }
  .rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #9c27b0 !important;
    color: #fff;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }

.rdtDays tr .dow {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #9c27b0; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #9c27b0;
  border-radius: 50%;
  border: 1px solid #9c27b0; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eee;
  -webkit-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #9c27b0 !important;
      color: #fff; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    .slick-slider .slick-caption {
      display: block !important; } }
  .slick-slider .slick-caption {
    padding-bottom: 45px;
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    color: #ffffff;
    text-align: center;
    z-index: 3;
    display: none; }
  .slick-slider .slick-slide > div:first-child {
    position: relative; }
  .slick-slider .slick-icons {
    position: relative;
    top: 5px; }
  .slick-slider .slick-image {
    width: 100% !important;
    display: inline-flex !important; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  height: 100%;
  padding: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 15%;
  z-index: 2;
  opacity: 0.5; }

.slick-prev {
  left: 0; }
  .slick-prev::before {
    content: "\f053";
    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: white;
    font-size: 30px;
    width: 100%; }

.slick-next {
  right: 0; }
  .slick-next::before {
    content: "\f054";
    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: #fff;
    font-size: 30px;
    width: 100%; }

.slick-list {
  z-index: 1; }

.slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 3; }

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer; }

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: "\2022";
    text-align: center;
    opacity: 1;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear; }

.slick-dots li.slick-active button:before {
  width: 15px;
  height: 15px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  top: -3px; }

.decolorify {
  -webkit-filter: invert(0.7);
          filter: invert(0.7); }

footer {
    background-color: #0f121a;
    margin-top: 110px;
    padding-top: 30px;
}

.footer-copyright {
    text-align: center;
    padding: 10px;
    background-color: rgb(12, 15, 20);
    color: white;
}

.container-fluid {
    padding-top: 15px;
    padding-bottom: 20px;
}

.text-white {
    color: white;
    margin-top: 0;
    padding-top: 0;
}

#cel_number  {
    color: rgb(202, 216, 255);
}

#email {
    color: rgb(202, 216, 255);
}

.footer-link {
    color: rgb(75, 123, 255);
}
.footer-list {
    margin-top: 0;
    padding-left: 20px;
}

.footer-link:hover {
    color: rgb(65, 116, 255);
}

.footer-list li {
    list-style: none;
    padding: 5px 0;
}

.footer-list li a {
    color: rgb(241, 241, 241);
}

.footer-list li a:hover {
    color: rgb(219, 219, 219);
}

.footer-row {
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
}

@media screen and (max-width: 820px) {
    .footer-list {
        margin-top: 30px;
    }
}
.content {
    margin: 5px;
}

.zoned-comfort-solutions {
  position: relative;
  top: 5px;
  width: 100%;
  min-height: 500px;
  background-color: rgb(245, 245, 245);
  padding: 30px 0 0;
  border-radius: 7px;
}

.zoned-comfort-solutions__intro {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.zoned-comfort-solutions__title-divider {
  height: 0;
  border-top: 1px solid #83838a;
  max-width: 200px;
  margin: 20px auto 25px;
}


.zoned-comfort-solutions__content {
  position: relative;
  max-width: 820px;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  padding: 0 0 20px 0;
}

.zoned-comfort-solutions__nav {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.zoned-comfort-solutions__nav-item {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 26px 0;
  background: #f9f9f9;
  font-size: 12px;
  width: 50%;
  position: relative;
  font-weight: 400;
}
.zoned-comfort-solutions__nav-item:after {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #90adad;
  width: 0px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
}

.zoned-comfort-solutions__nav-item .zoned-comfort-solutions__nav-item-button {
  color: #898989;
  line-height: 1;
}
.zoned-comfort-solutions__nav-item:hover,
.zoned-comfort-solutions__nav-item.active {
  border-top: 1px solid #90adad;
  border-bottom: 1px solid #90adad;
  background: #90adad;
}
.zoned-comfort-solutions__nav-item:hover:after,
.zoned-comfort-solutions__nav-item.active:after {
  display: block;
}
.zoned-comfort-solutions__nav-item:hover .zoned-comfort-solutions__nav-item-button,
.zoned-comfort-solutions__nav-item.active .zoned-comfort-solutions__nav-item-button {
  color: #f6f6f6;
}
.zoned-comfort-solutions__cta-slider {
  position: relative;
}
.zoned-comfort-solutions__cta-slider.move-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.zoned-comfort-solutions__cta-slider-window {
  overflow: hidden;
}



.zoned-comfort-solutions__cta-divider {
  width: 1px;
  background: #cbcbcb;
  height: 300px;
  margin: 30px 100px 0;
  display: inline-block;
  vertical-align: middle;
}

.zoned-comfort-solutions__cta-img {
  width: 125px;
  margin: 0 auto 25px;
}

.zoned-comfort-solutions__intro,
.zoned-comfort-solutions__content {
  position: relative;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.zoned-comfort-solutions--active {
  min-height: 0;
}
.zoned-comfort-solutions--active .zoned-comfort-solutions__intro,
.zoned-comfort-solutions--active .zoned-comfort-solutions__content {
  height: 0;
  left: 50%;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
}
.zoned-comfort-solutions .video-container-wrap {
  background: #f0f0f0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 60px 80px;
}

.zoned-comfort-solutions .video-container-wrap .btn--close {
  right: 90px;
  top: 30px;
}

/* 820 px */

@media screen and (max-width: 820px) {
  .zoned-comfort-solutions {
    padding: 60px 0 0;
    width: 100%;
  }

  .zoned-comfort-solutions__intro {
    padding-bottom: 60px;
  }

  .zoned-comfort-solutions__title {
    max-width: 300px;
    line-height: 62px;
    margin: 0 auto;
  }

   .zoned-comfort-solutions__description {
    max-width: 290px;
  }

  .zoned-comfort-solutions__content {
    background: #fff;
    padding: 0 0 10px;
  }

  .zoned-comfort-solutions__nav {
    display: flex;
  }

  .zoned-comfort-solutions__cta-slider {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    width: 200%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .zoned-comfort-solutions__cta-slider.move-left {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .zoned-comfort-solutions__cta-slider-window {
    margin-top: 55px;
    max-width: 91vw;
    margin: 0 auto;
  }

  .zoned-comfort-solutions__cta-divider {
    display: none;
  }

  .zoned-comfort-solutions .video-container-wrap {
    padding: 0 30px 30px;
    margin-top: -30px;
  }

  .zoned-comfort-solutions .video-container-wrap .btn--close {
    right: 60px;
  }
}



.zoned-comfort-solutions__cta-container {
  text-align: center;
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 30px; 
}

.zoned-comfort-solutions__cta-container-title-1,
.zoned-comfort-solutions__cta-container-title-2 {
  display: block;
  color: #525252;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;
}
.zoned-comfort-solutions__cta-container-title-1 {
  font-size: 28px;
}
.zoned-comfort-solutions__cta-container-title-2 {
  font-size: 16px;
  margin-top: 8px;
}
.zoned-comfort-solutions__cta-container-title-divider {
  max-width: 180px;
  margin: 15px auto;
  height: 0;
  border-top: 1px solid #969696;
}
.zoned-comfort-solutions__cta-container-copy {
  font-size: 14px;
  color: #525252;
  line-height: 25px;
  margin-bottom: 25px;
  font-weight: 400;
  opacity: 0.8;
}

@media screen and (max-width: 820px) {
  .zoned-comfort-solutions__cta-container {
    background: #fff;
    max-width: 90vw;
    margin: 0 auto;
  }

  .zoned-comfort-solutions__cta-container-copy {
    font-size: 16px;
    max-width: 98%;
    margin: 0 auto 20px;
  }
}
.buying-zoned-comfort {
  background: #aaaaaa;
  position: relative;
  display: flex;
  margin-top: 50px;
}

.buying-zoned-comfort__content {
  text-align: left;
  max-width: 500px;
  padding: 150px 0 150px 120px;
}


.buying-zoned-comfort__title br {
  display: none;
}

.buying-zoned-comfort__divider {
  max-width: 200px;
  height: 0;
  border: 1px solid #8f8f8f;
  text-align: left;
  margin: 20px 0 25px;
}


.buying-zoned-comfort__image-container {
  background: url(/static/media/room.44d7c8b5.jpg) center center no-repeat;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 56%;
  box-shadow: -30px 0px 30px -15px rgb(150, 150, 150);
}

.buying-zoned-comfort__image-container-webp {
  background: url(/static/media/room.333e36af.webp) center center no-repeat;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 56%;
  box-shadow: -30px 0px 30px -15px rgb(150, 150, 150);
}

@media screen and (max-width: 1440px) {
  .buying-zoned-comfort__image-container {
    width: 54%;
  }

  .buying-zoned-comfort__image-container-webp {
    width: 54%;
  }
}

@media screen and (max-width: 1113px) {
  .buying-zoned-comfort__content {
    padding-left: 40px;
  }
}

@media screen and (max-width: 820px) {
  .buying-zoned-comfort {
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 60px;
  }

   .buying-zoned-comfort__content {
    padding: 60px 0;
    max-width: 90%;
    margin: 0 auto;
  }

  .buying-zoned-comfort__title br {
    display: block;
  }

   .buying-zoned-comfort__image-container {
    width: 100%;
    min-height: 245px;
    position: relative;
    box-shadow: none;
  }

  .buying-zoned-comfort__image-container-webp {
    width: 100%;
    min-height: 245px;
    position: relative;
    box-shadow: none;
  }
}

.infoarea-container {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-around;
    margin: 60px auto;
}

@media screen and (max-width: 820px) {
    .infoarea-container {
        display: block;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 10px;
    }
}
.image-info {
    margin: 10px 0px 10px 40px;
    border-radius: 7px;
    width: 40%;
    height: auto;
}

.info-image {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}


@media screen and (max-width: 820px) {
    .image-info {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        border-radius: 17px;
    }

    .info-image {
        display: block;
    }

    .info-image-text {
        padding: 10px;
        margin: 20px;
    }
}

.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 700px;
  position: relative;
  border-radius: 7px; }
  .timeline__content-title {
    font-weight: normal;
    font-size: 66px;
    margin: -10px 0 0 0;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: "Pathway Gothic One", sans-serif;
    color: #fff; }
  .timeline__content-desc {
    margin: 0;
    font-size: 15px;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.7);
    font-family: Cardo;
    font-weight: normal;
    line-height: 25px; }
  .timeline:before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, 0.07); }
    @media only screen and (max-width: 767px) {
      .timeline:before {
        left: 40px; } }
  .timeline-item {
    padding: 40px 0;
    opacity: .3;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    -webkit-transition: .5s;
    transition: .5s;
    box-sizing: border-box;
    width: calc(50% - 40px);
    display: flex;
    position: relative;
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px); }
    .timeline-item:before {
      content: attr(data-text);
      letter-spacing: 3px;
      width: 100%;
      position: absolute;
      color: rgba(255, 255, 255, 0.5);
      font-size: 13px;
      font-family: "Pathway Gothic One", sans-serif;
      border-left: 2px solid rgba(255, 255, 255, 0.5);
      top: 70%;
      margin-top: -5px;
      padding-left: 15px;
      opacity: 0;
      right: calc(-100% - 56px); }
    .timeline-item:nth-child(even) {
      align-self: flex-end; }
      .timeline-item:nth-child(even):before {
        right: auto;
        text-align: right;
        left: calc(-100% - 56px);
        padding-left: 0;
        border-left: none;
        border-right: 2px solid rgba(255, 255, 255, 0.5);
        padding-right: 15px; }
    .timeline-item--active {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-filter: blur(0px);
              filter: blur(0px); }
      .timeline-item--active:before {
        top: 50%;
        -webkit-transition: .3s all .2s;
        transition: .3s all .2s;
        opacity: 1; }
      .timeline-item--active .timeline__content-title {
        margin: -50px 0 20px 0; }
    @media only screen and (max-width: 767px) {
      .timeline-item {
        align-self: baseline !important;
        width: 100%;
        padding: 0 30px 150px 80px; }
        .timeline-item:before {
          left: 10px !important;
          padding: 0 !important;
          top: 50px;
          text-align: center !important;
          width: 60px;
          border: none !important; }
        .timeline-item:last-child {
          padding-bottom: 40px; } }
  .timeline__img {
    max-width: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4); }
  .timeline-container {
    width: 100%;
    position: relative;
    padding: 40px 0;
    -webkit-transition: .3s ease 0s;
    transition: .3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
    border-radius: 7px; }
    .timeline-container:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(99, 99, 99, 0.8);
      content: "";
      border-radius: 7px; }
  .timeline-header {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative; }
    .timeline-header__title {
      color: #fff;
      padding: 10px;
      font-family: "Pathway Gothic One", sans-serif;
      margin: 0; }

.infoarea-container {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto;
}

@media screen and (max-width: 820px) {
    .infoarea-container {
        display: block;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 10px;
    }
}
.banner {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    width: 50%;
    box-shadow: 0 1px 20px rgba(199, 199, 199, 0.671);
}


@media screen and (max-width: 820px) {
    .about-page-banner {
        width: 100%;
    }

    .banner {
        width: 100%;
    }
}
.about-page-navpills {
    padding: 30px;
    margin: 0 auto;
    width: 80%;
}

.about-page-banner {
    background-color: rgba(252, 252, 252, 0.884);
    border-radius: 7px;
    box-shadow: 0 1px 20px rgba(199, 199, 199, 0.671);
    padding: 20px 20px 40px 20px;
    width: 50%;
}

@media screen and (max-width: 820px) {
    .about-page-navpills {
        width: 100%;
    }

    .about-page-banner {
        width: 100%;
    }
}
.product-details-img {
    padding: 80px 0;
    width: 85%;
    margin: 30px 30px 0 30px;
    display: block;
}

.product-details-img-small {
    display: block;
    width: 70px;
    height: auto;
    padding: 15px 5px;
    margin: 0px 0px 10px 10px;
    border: 2px solid rgb(15, 163, 231);
    cursor: pointer;
}

.product-details-image-wrapper {
    border: 1px solid rgb(190, 190, 190);
    margin:20px;
    padding:0;
}
.table-data-modal td {
  border: none;
}
.product-wrapper {
  width: 300px;
  height: 500px;
  background: white;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  -webkit-transition: box-shadow 0.5s, -webkit-transform 0.5s;
  transition: box-shadow 0.5s, -webkit-transform 0.5s;
  transition: box-shadow 0.5s, transform 0.5s;
  transition: box-shadow 0.5s, transform 0.5s, -webkit-transform 0.5s;
  cursor: pointer; }
  .product-wrapper:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2); }
  .product-wrapper .product-container {
    width: 100%;
    height: 100%; }
    .product-wrapper .product-container .product-top {
      height: 80%;
      width: 100%;
      background-size: 100%; }
    .product-wrapper .product-container .product-bottom {
      width: 200%;
      height: 20%;
      -webkit-transition: -webkit-transform 0.5s;
      transition: -webkit-transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s; }
      .product-wrapper .product-container .product-bottom.clicked {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .product-wrapper .product-container .product-bottom h1 {
        margin: 0;
        padding: 0; }
      .product-wrapper .product-container .product-bottom p {
        margin: 0;
        padding: 0; }
      .product-wrapper .product-container .product-bottom .product-left {
        height: 100%;
        width: 50%;
        background: #f4f4f4;
        position: relative;
        float: left; }
        .product-wrapper .product-container .product-bottom .product-left .product-details {
          padding: 20px;
          float: left;
          width: calc(70% - 40px); }
        .product-wrapper .product-container .product-bottom .product-left .buy {
          float: right;
          width: calc(30% - 2px);
          height: 100%;
          background: #f1f1f1;
          -webkit-transition: background 0.5s;
          transition: background 0.5s;
          border-left: solid thin rgba(0, 0, 0, 0.1); }
          .product-wrapper .product-container .product-bottom .product-left .buy i {
            font-size: 30px;
            padding: 30px;
            color: #254053;
            -webkit-transition: -webkit-transform 0.5s;
            transition: -webkit-transform 0.5s;
            transition: transform 0.5s;
            transition: transform 0.5s, -webkit-transform 0.5s; }
          .product-wrapper .product-container .product-bottom .product-left .buy:hover {
            background: #A6CDDE; }
          .product-wrapper .product-container .product-bottom .product-left .buy:hover i {
            -webkit-transform: translateY(5px);
                    transform: translateY(5px);
            color: #00394B; }
      .product-wrapper .product-container .product-bottom .right {
        width: 50%;
        background: #A6CDDE;
        color: white;
        float: right;
        height: 200%;
        overflow: hidden; }
        .product-wrapper .product-container .product-bottom .right .details {
          padding: 20px;
          float: right;
          width: calc(70% - 40px); }
        .product-wrapper .product-container .product-bottom .right .done {
          width: calc(30% - 2px);
          float: left;
          -webkit-transition: -webkit-transform 0.5s;
          transition: -webkit-transform 0.5s;
          transition: transform 0.5s;
          transition: transform 0.5s, -webkit-transform 0.5s;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%; }
          .product-wrapper .product-container .product-bottom .right .done i {
            font-size: 30px;
            padding: 30px;
            color: white; }
        .product-wrapper .product-container .product-bottom .right .remove {
          width: calc(30% - 1px);
          clear: both;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;
          background: #BC3B59;
          -webkit-transition: background 0.5s, -webkit-transform 0.5s;
          transition: background 0.5s, -webkit-transform 0.5s;
          transition: transform 0.5s, background 0.5s;
          transition: transform 0.5s, background 0.5s, -webkit-transform 0.5s; }
          .product-wrapper .product-container .product-bottom .right .remove:hover {
            background: #9B2847; }
          .product-wrapper .product-container .product-bottom .right .remove:hover i {
            -webkit-transform: translateY(5px);
                    transform: translateY(5px); }
          .product-wrapper .product-container .product-bottom .right .remove i {
            -webkit-transition: -webkit-transform 0.5s;
            transition: -webkit-transform 0.5s;
            transition: transform 0.5s;
            transition: transform 0.5s, -webkit-transform 0.5s;
            font-size: 30px;
            padding: 30px;
            color: white; }
        .product-wrapper .product-container .product-bottom .right:hover .remove, .product-wrapper .product-container .product-bottom .right:hover .done {
          -webkit-transform: translateY(-100%);
                  transform: translateY(-100%); }
  .product-wrapper .inside {
    z-index: 9;
    background: #3b88a7;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    -webkit-transition: all 0.5s, border-radius 2s, top 1s;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden; }
    .product-wrapper .inside .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 1; }
    .product-wrapper .inside:hover {
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80%; }
      .product-wrapper .inside:hover .icon {
        opacity: 0;
        right: 15px;
        top: 15px; }
      .product-wrapper .inside:hover .contents {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-transform: translateY(0);
                transform: translateY(0); }
    .product-wrapper .inside .contents {
      padding: 5%;
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
      -webkit-transition: opacity 0.2s, -webkit-transform 0.8s;
      transition: opacity 0.2s, -webkit-transform 0.8s;
      transition: opacity 0.2s, transform 0.8s;
      transition: opacity 0.2s, transform 0.8s, -webkit-transform 0.8s; }
      .product-wrapper .inside .contents table {
        text-align: left;
        width: 100%; }
      .product-wrapper .inside .contents h1, .product-wrapper .inside .contents p, .product-wrapper .inside .contents table {
        color: white; }
      .product-wrapper .inside .contents p {
        font-size: 13px; }

.product-item-modal {
  width: 60%;
  background-color: white;
  border-radius: 7px;
  margin: 50px auto;
  padding: 20px;
  display: flex;
  justify-content: space-around; }

@media screen and (max-width: 820px) {
  .product-item-modal {
    display: block;
    width: 90%;
    height: 80vh;
    overflow: scroll; } }

.products-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    height: 1100px;
    overflow: scroll;
    padding-bottom: 50px;
}

.products-outer-wrapper {
    width: 70%;
}
.loader,
.loader:before,
.loader:after {
  background: #3f86ac;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #3f86ac;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 6px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.products-filter-title {
    background-color: rgb(50, 125, 187);
    padding: 10px;
    box-shadow: 0 6px 4px -4px rgb(185, 185, 185);
}

.products-filter-title h5 {
    display: inline-block;
    color: white;
    margin: 0 0 0 15px;
    font-weight: 400;
}
.color-palette {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.circle {
  border-radius: 18px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 15px;
  border: 1px solid rgba(151, 151, 151, 0.582);
}

.circle-outline {
    border: 3px solid rgb(120, 151, 255);
    box-shadow: 0 0 2px #888;
}
.products-filter-wrapper {
    padding: 0;
    width: 280px;
    background-color: white;
    margin: 15px 30px;
}
.products-filter-body {
    padding: 0 20px;
}

.products-filter-body p {
    font-size: 17px;
    font-weight: 700;
    padding: 0;
    margin: 15px 0 0 0px;
}

hr { 
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgb(219, 219, 219);
    margin-top: 0.5em;
    padding: 0; 
    width: 90%;
}
.products-page {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.products-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto;
    padding: 12px;
    width: 30%;
    list-style: none;
    background-color: white;
    border-radius: 7px;
}

.products-breadcrumbs {
    flex: 0 0 93%;
    margin-bottom: 20px;
}

.products-pagination-link {
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    padding: 7px 12px;
}

.products-pagination-link-next a {
    color: rgb(60, 107, 160);
    cursor: pointer;
}

.products-pagination-link-previous a {
    color: rgb(60, 107, 160);
    cursor: pointer;
}

.products-pagination-link-active {
    background-color: rgb(60, 107, 160)
}

.products-pagination-a-link {
    color: rgb(48, 91, 155);
    cursor: pointer;
}

.products-pagination-a-link:hover {
    color: rgb(48, 91, 155);
}

.products-pagination-a-link-active {
    color: white !important;
}

.filter-mobile-expansion {
    display: none;
}

@media screen and (max-width: 1150px) {
    .filter-mobile-expansion {
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    .filter-drawer {
        display: none;
    }

    .products-filter-wrapper {
        width: 100%;
    }

    .products-pagination {
        width: 90%;
        margin: 0 auto;
    }

    .products-outer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 0;
    }

    .products-page {
        display: block;
    }

    .products-filter-title {
        display: none;
    }

    .products-breadcrumbs {
        width: 90%;
        margin: 15px auto;
    }
}
td {
    padding: 10px;
    border: 0.25px solid rgb(255, 255, 255);
}

th {
    padding: 10px;
}

table {
    width: 100%;
    border-spacing: 0 !important;
}
.product-details-info-page {
    padding: 10px;
    margin: 10px;
}
.image-and-info-details {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin: 20px auto;
}

@media screen and (max-width: 820px) {
    .image-and-info-details {
        display: block;
    }
}
.similar-products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px;
    background-color: rgb(240, 239, 239);
    border-radius: 7px;
    margin-bottom: 30px;
}
.product-details-page {
    border-radius: 7px;
    background-color: white;
    width:95vw;
    margin: 100px auto;
    min-height: 700px;
}

.benefits-list {
  background-color: #f9f9f9;
}
.benefits-list span {
  background-color: #f9f9f9;
  display: block;
  padding: 86px 0;
  -webkit-transition: 0.25s background-color ease;
  transition: 0.25s background-color ease;
}
.benefits-list .benefit-wrapper {
  margin: 0 auto;
  max-width: 760px;
  padding: 0 66px 0 160px;
  position: relative;
  width: 90%;
}
.benefits-list .benefit-wrapper:before {
  background: url(/static/media/icon-benefits.d98ac5ed.png) no-repeat left top;
  background-size: 120px 120px;
  display: block;
  height: 120px;
  overflow: hidden;
  position: relative;
  text-indent: 100%;
  width: 120px;
  white-space: nowrap;
  content: '';
  left: 0;
  margin-top: -60px;
  position: absolute;
  top: 50%;
  background-size: inherit;
}

.benefits-list .benefit-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 90px;
}
.benefits-list h3 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALEAAAABAQMAAACrLd3zAAAAA1BMVEUAAACnej3aAAAAAXRSTlNANjqZ9gAAAApJREFUCB1jwAEAABgAAVKOE4gAAAAASUVORK5CYII=) no-repeat left bottom;
  color: #5f5f5f;
  font-size: 30px;
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
.benefits-list p {
  color: #848484;
  font-size: 16px;
  margin: 0;
}
.benefits-list span:hover .benefit-wrapper:before {
  background-position: right top;
}
.benefits-list span:hover .benefit-wrapper:after {
  opacity: 1;
}
.benefits-list .benefit:nth-child(1) span {
  padding-top: 100px;
}
.benefits-list .benefit:nth-child(1) span:hover {
  background-color: #97c5e5;
}
.benefits-list .benefit:nth-child(1) span:hover h3 {
  color: #456f8c;
}
.benefits-list .benefit:nth-child(1) span:hover p {
  color: #3c5f78;
}
.benefits-list .benefit:nth-child(2) span .benefit-wrapper:before {
  background-position: left -228px;
}
.benefits-list .benefit:nth-child(2) span:hover {
  background-color: #3d7f3a;
}
.benefits-list .benefit:nth-child(2) span:hover .benefit-wrapper:before {
  background-position: right -228px;
}
.benefits-list .benefit:nth-child(2) span:hover h3 {
  color: #173516;
}
.benefits-list .benefit:nth-child(2) span:hover p {
  color: #265224;
}
.benefits-list .benefit:nth-child(3) span .benefit-wrapper:before {
  background-position: left -456px;
}
.benefits-list .benefit:nth-child(3) span:hover {
  background-color: #ff671b;
}
.benefits-list .benefit:nth-child(3) span:hover .benefit-wrapper:before {
  background-position: right -456px;
}
.benefits-list .benefit:nth-child(3) span:hover h3 {
  color: #a33906;
}
.benefits-list .benefit:nth-child(3) span:hover p {
  color: #aa3f0a;
}
.benefits-list .benefit:nth-child(4) span .benefit-wrapper:before {
  background-position: left -684px;
}
.benefits-list .benefit:nth-child(4) span:hover {
  background-color: #b096da;
}
.benefits-list .benefit:nth-child(4) span:hover .benefit-wrapper:before {
  background-position: right -684px;
}
.benefits-list .benefit:nth-child(4) span:hover h3 {
  color: #665482;
}
.benefits-list .benefit:nth-child(4) span:hover p {
  color: #5c4b76;
}
.benefits-list .benefit:nth-child(5) span .benefit-wrapper:before {
  background-position: left -912px;
}
.benefits-list .benefit:nth-child(5) span:hover {
  background-color: #63d0df;
}
.benefits-list .benefit:nth-child(5) span:hover .benefit-wrapper:before {
  background-position: right -912px;
}
.benefits-list .benefit:nth-child(5) span:hover h3 {
  color: #1a6777;
}
.benefits-list .benefit:nth-child(5) span:hover p {
  color: #29727f;
}
.benefits-list .benefit:nth-child(6) span {
  padding-bottom: 100px;
}
.benefits-list .benefit:nth-child(6) span .benefit-wrapper:before {
  background-position: left -1140px;
}
.benefits-list .benefit:nth-child(6) span:hover {
  background-color: #f7d93e;
}
.benefits-list .benefit:nth-child(6) span:hover .benefit-wrapper:before {
  background-position: right -1140px;
}
.benefits-list .benefit:nth-child(6) span:hover h3 {
  color: #947e0f;
}
.benefits-list .benefit:nth-child(6) span:hover p {
  color: #84700b;
}
@media screen and (max-width: 640px) {
  .benefits-list span {
    padding: 0;
  }
  .benefits-list .benefit-wrapper {
    border-bottom: 1px solid #e1e6eb;
    padding: 52px 0;
    text-align: center;
    width: 80%;
  }
  .benefits-list .benefit-wrapper:before {
    background-size: 205px auto;
    height: 89px;
    margin: 0 auto 2em;
    position: static;
    width: 89px;
  }
  .benefits-list .benefit-wrapper:after {
    display: none;
  }
  .benefits-list h3 {
    background-position: center bottom;
    background-size: 90px 2px;
    font-size: 24px;
    margin-bottom: 1em;
    padding-bottom: 0.75em;
  }
  .benefits-list p {
    font-size: 14px;
    padding: 0 10%;
  }
  .benefits-list .benefit:nth-child(1) span {
    padding-top: 0;
  }
  .benefits-list .benefit:nth-child(2) span .benefit-wrapper:before {
    background-position: left -166px;
  }
  .benefits-list .benefit:nth-child(2) span:hover .benefit-wrapper:before {
    background-position: right -166px;
  }
  .benefits-list .benefit:nth-child(3) span .benefit-wrapper:before {
    background-position: left -334px;
  }
  .benefits-list .benefit:nth-child(3) span:hover .benefit-wrapper:before {
    background-position: right -334px;
  }
  .benefits-list .benefit:nth-child(4) span .benefit-wrapper:before {
    background-position: left -501px;
  }
  .benefits-list .benefit:nth-child(4) span:hover .benefit-wrapper:before {
    background-position: right -501px;
  }
  .benefits-list .benefit:nth-child(5) span .benefit-wrapper:before {
    background-position: left -666px;
  }
  .benefits-list .benefit:nth-child(5) span:hover .benefit-wrapper:before {
    background-position: right -666px;
  }
  .benefits-list .benefit:nth-child(6) span {
    padding-bottom: 0;
  }
  .benefits-list .benefit:nth-child(6) span .benefit-wrapper:before {
    background-position: left -834px;
  }
  .benefits-list .benefit:nth-child(6) span:hover .benefit-wrapper:before {
    background-position: right -834px;
  }
}
.benefits-tagline {
  background: #f2f0f4;
  color: #909096;
  margin: 0 auto;
  max-width: 1800px;
  padding: 50px 0;
  text-align: center;
  width: 100%;
  border-radius: 7px;
}

.banner {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    width: 50%;
}

.banner-title {
    color: rgb(120,120,120);
    font-weight: 400
}

.banner-subtitle {
    color: rgb(100,100,100);
}

@media screen and (max-width: 640px) {
    .banner {
        width: 100%
    }

    .banner-title {
        font-size: 40px
    }

    .banner-subtitle {
        font-size: 15px;
    }

    .info-subtitle {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  margin: 1.25px;
  border: 1.25px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.form-container {
    margin: 0 40px;
    padding: 10px 40px;
}

.form-container-title {
    margin-bottom: 0;
    padding-bottom: 0;
}

.form-post-fail {
    color: rgb(242, 31, 60);
    margin-left: 20px;
    font-size: 12px;
}

.form-post-success {
    color: rgb(68, 217, 63);
    margin-left: 20px;
    font-size: 12px;
}

@media screen and (max-width: 820px) { 
    .form-container {
        padding: 10px;
        margin: 0 20px;
    }
}
.contact-page-container {
    width: 90vw;
    background-color: rgb(255, 255, 255);
    margin: 120px auto;
    padding: 20px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.contact-page-info {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(192, 192, 192);
}

@media screen and (max-width: 820px) { 
    .contact-page-info {
        border-left: none;
        border-top: 1px solid rgb(226, 226, 226);
    }

    .contact-page-container { 
        display: block;
    }

    .contact-page-info img {
        width: 65%;
    }
}

#notfound {
  position: relative;
  height: 100vh; }

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4; }

.notfound .notfound-404 {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0px auto 50px; }

.notfound .notfound-404 > div:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffa200;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 5px dashed #000;
  border-radius: 5px; }

.notfound .notfound-404 > div:first-child:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
  border-radius: 5px; }

.notfound .notfound-404 h1 {
  font-family: 'Cabin', sans-serif;
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 90px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  height: 40px;
  line-height: 40px; }

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 33px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 7px; }

.notfound p {
  font-family: 'Cabin', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400; }

.notfound a {
  font-family: 'Cabin', sans-serif;
  display: inline-block;
  padding: 10px 25px;
  background-color: #8f8f8f;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; }

.notfound a:hover {
  background-color: #2c2c2c; }

#notfound + footer {
  margin-top: 0; }

