.image-info {
    margin: 10px 0px 10px 40px;
    border-radius: 7px;
    width: 40%;
    height: auto;
}

.info-image {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}


@media screen and (max-width: 820px) {
    .image-info {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        border-radius: 17px;
    }

    .info-image {
        display: block;
    }

    .info-image-text {
        padding: 10px;
        margin: 20px;
    }
}