.infoarea-container {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto;
}

@media screen and (max-width: 820px) {
    .infoarea-container {
        display: block;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 10px;
    }
}